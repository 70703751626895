export default defineAppConfig({
  footer: {
    fontWeight: "regular",
    divider: {
      height: "3px",
      background: "#cfd8de",
      "border-radius": "2px",
    },
  },
  teamsVisibility: {
    shopware: true,
    appDev: true,
    AI: true,
    mobileApps: true,
    devOps: true,
  },
  textColorPrimaryBackground: "0,0,0",
  employeeRoleDataContainerClasses: [],
  projectManagerClasses: [],
});
